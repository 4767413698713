import React, { useState, useEffect } from 'react';
import { func, string, oneOfType, object, bool } from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { DateRange } from '@material-ui/icons';
import { DATE_DISPLAY, MAX_DATE, MIN_DATE, DATE_ISO, CLOCK_ISO } from './constants';
import Calendar from './calendar';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(0.75),
  },
  modal: {
    position: 'relative',
  },
  calendar: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
  },
  icon: {
    fill: palette.primary.main,
    width: spacing(2.5),
    height: spacing(2.5),
    margin: spacing(0, 0.5),
  },
  display: {
    cursor: 'pointer',
  },
}));

/*
 * DatePicker
 * @param {String} name
 * @param {Object} value - moment object
 * @param {Function} selectDate - mapped to setFieldValue of Formik
 */
const DatePicker = ({ name, value, selectDate, time, format }) => {
  const s = useStyles();
  const [selectedDate, setDate] = useState(moment());
  const [selectedTime, setTime] = useState('00:00:00');
  const [isOpenCalendar, setCalendar] = useState(false);

  useEffect(() => {
    setTime(moment(value).isValid() ? moment(value).format(CLOCK_ISO) : '');
  }, [value]);

  const handleChangeDate = date => {
    setDate(date);
  };

  const handleChangeTime = timeSelected => {
    setTime(timeSelected);
  };

  const handleOpenCalendar = () => {
    setCalendar(true);
  };

  const handleCloseCalendar = () => {
    const dateString = selectedDate.format(DATE_ISO);
    const dateTimeString = time
      ? `${dateString} ${selectedTime || '00:00:00'}`
      : dateString;
    selectDate(name, dateTimeString);
    setCalendar(false);
  };

  const timeCta = time ? handleChangeTime : null;

  const calendar = (
    <Modal className={s.modal} open>
      <Grid component="div" container justify="center" className={s.calendar}>
        <Calendar
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          date={selectedDate}
          onDateChanged={handleChangeDate}
          onTimeChanged={timeCta}
          onClose={handleCloseCalendar}
        />
      </Grid>
    </Modal>
  );

  const currentDisplay = value ? moment(value).format(format) : 'Chọn ngày và giờ';

  return (
    <Grid component="div" container className={s.root}>
      <Grid component="div" item onClick={handleOpenCalendar}>
        <Grid component="div" container alignItems="center">
          <DateRange className={s.icon} />
          <Typography className={s.display} variant="body2">
            {currentDisplay} {selectedTime}
          </Typography>
        </Grid>
      </Grid>
      <Grid component="div" item>
        {isOpenCalendar ? calendar : null}
      </Grid>
    </Grid>
  );
};

DatePicker.propTypes = {
  name: string.isRequired,
  value: oneOfType([object, string]),
  selectDate: func.isRequired,
  format: string,
  time: bool,
};

DatePicker.defaultProps = {
  format: DATE_DISPLAY,
  time: false,
  value: '',
};

export default DatePicker;
