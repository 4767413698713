import get from 'lodash/get';
import axios from './config';
import URL from '../constants/url';

export const login = data =>
  axios
    .post(URL.LOGIN, data)
    .then(response => ({
      status: 200,
      data: get(response, 'data'),
    }))
    .catch(error => ({
      status: get(error, 'response.status'),
      data: 501,
    }));

export default null;
