import React from 'react';
import Login from '../components/admin/login';
import Loading from '../components/loading';
import Error from '../components/error';
import Layout from '../components/layout';

const AdminDealCreation = () => (
  <>
    <Layout>
      <Login />
    </Layout>
    <Loading />
    <Error />
  </>
);

AdminDealCreation.propTypes = {};

AdminDealCreation.defaultProps = {};

export default AdminDealCreation;
