/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react';
import { string, func, objectOf, any } from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import Typo from '@material-ui/core/Typography';
import Photo from '@material-ui/icons/PhotoCamera';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import HomeGrid from './HomeGrid';
import imageUrl from '../utils/image';
import useClickOff from '../hooks/useClickOff';
import COLORS from '../constants/colors';

const useStyles = makeStyles(
  ({ spacing, palette, typography, shape, zIndex, transitions }) => ({
    root: {
      position: 'relative',
      margin: spacing(0.5, 0),
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: '90%',
    },
    icon: {
      width: spacing(3),
      height: spacing(3),
      margin: spacing(0, 1),
      fill: palette.secondary.light,
    },
    iActive: {
      fill: palette.primary.main,
      cursor: 'pointer',
    },
    iFloat: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: spacing(0),
    },
    input: {
      width: 0,
    },
    fileName: {
      fontSize: typography.body2.fontSize,
      maxWidth: spacing(33.75),
    },
    preview: {
      position: 'absolute',
      top: 0,
      left: 0,
      maxWidth: spacing(40),
      height: 0,
      overflow: 'hidden',
      transition: transitions.easing.custom,
    },
    showPreview: {
      height: spacing(24),
      borderRadius: shape.rectRadius,
      zIndex: zIndex.tooltip,
      boxShadow: COLORS.SHADOW_STANDARD,
    },
    picture: {
      width: '100%',
      height: '100%',
    },
  }),
);

const HomeImage = ({ initImage, name, onChange, onBlur }) => {
  const s = useStyles();
  const [openedFile, setOpenFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [togglePreview, setTogglePreview] = useState(false);

  const imageRef = useRef();
  const previewRef = useRef();

  useEffect(() => {
    const url = get(initImage, 'url');
    const src = url ? imageUrl(url) : null;
    setPreview(src);
    setOpenFile(initImage);
  }, [initImage]);

  const handleOpenFile = () => {
    onBlur(name);
    imageRef.current.click();
  };

  const handleChange = evt => {
    evt.preventDefault();
    const {
      target: { files },
    } = evt;
    const file = get(files, '0', null);
    const size = get(file, 'size', 0);
    setOpenFile(file);
    onChange(file);
    setPreview(null);
    if (size > 0) {
      const fileReader = new FileReader();
      fileReader.onload = data => {
        const result = get(data, 'target.result', null);
        setPreview(result);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handlePreview = () => {
    setTogglePreview(!togglePreview);
  };

  const handleClickOutside = () => {
    if (togglePreview) {
      setTogglePreview(!togglePreview);
    }
  };

  useClickOff(previewRef, handleClickOutside);

  const View = togglePreview && Boolean(preview) ? VisibilityOff : Visibility;

  return (
    <>
      <HomeGrid className={s.root} onClick={handleOpenFile}>
        <Photo className={classNames(s.icon, s.iActive)} />
        <input
          ref={imageRef}
          id={name}
          type="file"
          className={s.input}
          hidden
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleChange}
        />
        {openedFile && (
          <Typo className={s.fileName} noWrap>
            {get(openedFile, 'name')}
          </Typo>
        )}
        <HomeGrid
          ref={previewRef}
          className={classNames(s.preview, preview && togglePreview && s.showPreview)}
        >
          {preview && <img src={preview} alt="Home Preview" className={s.picture} />}
        </HomeGrid>
      </HomeGrid>
      <View
        className={classNames(s.icon, s.iFloat, preview && s.iActive)}
        onClick={handlePreview}
      />
    </>
  );
};

HomeImage.propTypes = {
  name: string,
  onChange: func,
  onBlur: func,
  initImage: objectOf(any),
};

HomeImage.defaultProps = {
  name: 'file',
  onChange: noop,
  onBlur: noop,
  initImage: {},
};

export default HomeImage;
