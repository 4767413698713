import axios from './config';
import URL from '../constants/url';
import { createHeaders } from '../utils/helpers';

export const createBrand = (data, token) =>
  axios.post(URL.BRAND, data, { ...createHeaders(token) });
export const getBrands = (data, token) =>
  axios.get(`${URL.BRAND}${data}`, {
    ...createHeaders(token),
  });
export const getBranches = (data, token) =>
  axios.get(`${URL.BRANCH}${data}`, {
    ...createHeaders(token),
  });
export const createBranch = (data, token) =>
  axios.post(URL.BRANCH, data, { ...createHeaders(token) });
export const updateBrand = (data, token) =>
  axios.put(`${URL.BRAND}/${data.id}`, data, { ...createHeaders(token) });
export const updateBranch = (data, token) =>
  axios.put(`${URL.BRANCH}/${data.id}`, data, { ...createHeaders(token) });
export const deleteBrand = (data, token) =>
  axios.delete(`${URL.BRAND}/${data}`, { ...createHeaders(token) });
export const deleteBranch = (data, token) =>
  axios.delete(`${URL.BRANCH}/${data}`, { ...createHeaders(token) });
