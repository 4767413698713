/* eslint-disable dot-notation */
import axios from './config';
import URL from '../constants/url';

export const uploadFile = (data, token) =>
  axios.post(URL.UPLOAD, data, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data['_boundary']}`,
      Authorization: `Bearer ${token}`,
    },
  });
