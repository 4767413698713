import React, { useEffect } from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import ROUTER from '../../constants/router';
import FORM from '../../constants/form';
import Loading from '../loading';
import Footer from '../footer';
import Error from '../error';
import { login } from '../../utils/schemas';
import { authProps } from './props';
import Banner from './Banner';
import HomeGrid from '../HomeGrid';
import HomeInput from '../HomeInput';
import HomeSubmit from '../HomeSubmit';
import CTA from '../../constants/cta';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    margin: 0,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '5%',
  },
}));

const Login = ({ dispatchLogin, isAuth }) => {
  const s = useStyles();
  const { LOGIN } = FORM;

  useEffect(() => {
    if (isAuth) {
      navigate(ROUTER.ADMIN.DASHBOARD);
    }
  }, [isAuth]);

  const handleLogin = values => {
    const { email: identifier, password } = values;
    dispatchLogin({ identifier, password });
  };

  const initialValue = {
    [LOGIN[0].name]: '',
    [LOGIN[1].name]: '',
  };

  return (
    <>
      <Error />
      <Loading />
      <HomeGrid
        fluid
        direction="column"
        justify="space-between"
        alignItems="center"
        className={s.root}
      >
        <HomeGrid fluid direction="column" alignItems="center">
          <Banner />
          <HomeGrid fluid justify="center">
            <Formik
              initialValues={initialValue}
              enableReinitialize
              validateOnBlur
              validateOnMount
              validateOnChange
              initialErrors={false}
              initialTouched={false}
              validationSchema={login}
              onSubmit={handleLogin}
            >
              {({
                values,
                isValid,
                dirty,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setTouched,
              }) => {
                const handleTouched = evt => {
                  let name;
                  if (evt.preventDefault) {
                    evt.preventDefault();
                    name = get(evt, 'target.name');
                  } else {
                    name = evt;
                  }
                  setTouched({ ...touched, [name]: true });
                };

                return (
                  <form onSubmit={handleSubmit} className={s.form}>
                    <HomeGrid fluid direction="column" alignItems="center">
                      {LOGIN.map(field => {
                        return (
                          <HomeInput
                            key={field.name}
                            id={field.name}
                            type={field.type}
                            label={field.label}
                            name={field.name}
                            onChange={handleChange}
                            errors={errors}
                            onBlur={handleTouched}
                            touched={touched}
                            values={values}
                          />
                        );
                      })}
                      <HomeSubmit label={CTA.LABELS.LOGIN} valid={dirty && isValid} />
                    </HomeGrid>
                  </form>
                );
              }}
            </Formik>
          </HomeGrid>
        </HomeGrid>
        <Footer />
      </HomeGrid>
    </>
  );
};

Login.propTypes = {
  dispatchLogin: func.isRequired,
  isAuth: bool,
};

Login.defaultProps = {
  isAuth: false,
};

export default connect(authProps.mapStateToProps, authProps.mapDispatchToProps)(Login);
