import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import ICheck from '../svgs/ic-checked.svg';
import IUCheck from '../svgs/ic-unchecked.svg';
import HomeGrid from './HomeGrid';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing(4.25),
    height: spacing(4.25),
    padding: 0,
    cursor: 'pointer',
  },
  hidden: {
    visibility: 'hidden',
    width: 0,
    margin: 0,
  },
  icon: {
    width: spacing(4),
    height: spacing(4),
  },
  checked: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const HomeCheckbox = ({ value, onCheck, ...rest }) => {
  const s = useStyles();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(value);
  }, [value]);

  const handleCheck = evt => {
    const {
      target: { checked },
    } = evt;
    setActive(checked);
    onCheck(evt);
  };

  return (
    <HomeGrid className={s.root}>
      <input
        type="checkbox"
        {...rest}
        checked={active}
        className={s.hidden}
        onChange={handleCheck}
      />
      <IUCheck className={s.icon} />
      {active && <ICheck className={classNames(s.icon, s.checked)} />}
    </HomeGrid>
  );
};

HomeCheckbox.propTypes = {
  onCheck: func.isRequired,
  value: bool,
};

HomeCheckbox.defaultProps = {
  value: false,
};

export default HomeCheckbox;
