import moment from 'moment';

const NO_OF_DAYS = 7;
const NO_OF_MONTHS = 12;
export const MAX_DATE = moment().add(11, 'y');
export const MIN_DATE = moment().subtract(1, 'd');
export const DATE_DISPLAY = 'DD/MM/YYYY';
export const DATE_ISO = 'YYYY-MM-DD';
export const CLOCK = 'HH:mm';
export const HOUR_TIME = /(\d+):\d+/;
export const MINUTE_TIME = /\d+:(\d+)/;
export const CLOCK_ISO = 'HH:mm:ss';
export const CALENDAR_WEEKS = 6;
export const DAYS_OF_WEEK = 7;
export const YEAR_ROW = 3;
export const MONTH_ROW = 3;
export const WEEKDAYS = [
  ...Array.from({ length: NO_OF_DAYS }, (_, index) => {
    const value = index + 1;
    const name = index === 0 ? 'CN' : `T${value}`;
    const longName = index === 0 ? 'Chủ Nhật' : `Thứ ${value + 1}`;
    return { name, value, longName };
  }),
];
export const MONTHS = [
  ...Array.from({ length: NO_OF_MONTHS }, (_, index) => {
    const value = index + 1;
    return { value, name: `Tháng ${`${value}`.padStart(2, '0')}` };
  }),
];
export const MONTH_NAMES = MONTHS.reduce(
  (acc, cur) => ({
    ...acc,
    [cur.value]: cur.name,
  }),
  {},
);
export const DATE_NAME = {
  0: 'Chủ Nhật',
  1: 'Thứ Hai',
  2: 'Thứ Ba',
  3: 'Thứ Tư',
  4: 'Thứ Năm',
  5: 'Thứ Sáu',
  6: 'Thứ Bảy',
};
