import React from 'react';
import { bool, string } from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  root: {
    border: 'none',
    outline: 'none',
    width: '100%',
    maxWidth: spacing(40),
    marginTop: spacing(2),
    padding: spacing(1.5),
    borderRadius: shape.rectRadius,
    background: palette.secondary.light,
    textTransform: 'uppercase',
  },
  active: {
    background: palette.primary.dark,
    color: palette.primary.contrastText,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const HomeSubmit = ({ valid, label }) => {
  const s = useStyles();

  return (
    <button
      type="submit"
      disabled={!valid}
      className={classNames(s.root, valid && s.active)}
    >
      {label}
    </button>
  );
};

HomeSubmit.propTypes = {
  valid: bool,
  label: string.isRequired,
};

HomeSubmit.defaultProps = {
  valid: false,
};

export default HomeSubmit;
