import { loginApi, logoutAction } from '../../actions/auth';
import {
  createDealApi,
  updateDealApi,
  deleteDealApi,
  resetDealAction,
  adminDealApi,
  fetchDealDetail,
  adminSearchApi,
  setAdminSearchText,
} from '../../actions/deal';
import {
  createBrandApi,
  getBrandsApi,
  createBranchApi,
  resetBrandData,
  getBranchesApi,
  updateBrandApi,
  updateBranchApi,
  deleteBrandApi,
  deleteBranchApi,
} from '../../actions/brand';
import { uploadFileApi, resetFile } from '../../actions/file';

export const authProps = {
  mapStateToProps: ({ common, auth }) => ({
    isLoading: common.isLoading,
    isAuth: auth.isAuth,
  }),
  mapDispatchToProps: dispatch => ({
    dispatchLogin: data => dispatch(loginApi(data)),
  }),
};

export const dashboardProps = {
  mapStateToProps: ({ deal, auth, brand }) => ({
    dealDetail: deal.dealDetail,
    isSuccess: deal.isSuccess,
    dealList: deal.dealList,
    userDetail: auth.userDetail,
    isAuth: auth.isAuth,
    dealAdmin: deal.dealAdmin,
    adminSearchText: deal.adminSearchText,
    configs: deal.configs,
    brands: brand.brands,
    branches: brand.branches,
  }),
  mapDispatchToProps: dispatch => ({
    // deal
    dispatchFetchDealDetail: payload => dispatch(fetchDealDetail(payload)),
    dispatchCreateDeal: (data, token) => dispatch(createDealApi(data, token)),
    dispatchUpdateDeal: (data, token) => dispatch(updateDealApi(data, token)),
    dispatchDeleteDeal: (payload, token) => dispatch(deleteDealApi(payload, token)),
    dispatchResetDeal: () => dispatch(resetDealAction()),
    dispatchAdminDeal: (data, token) => dispatch(adminDealApi(data, token)),
    dispatchAdminSearch: (data, token) => dispatch(adminSearchApi(data, token)),
    dispatchSetAdminSearchText: payload => dispatch(setAdminSearchText(payload)),
    // file
    dispatchUploadFile: (payload, token) => dispatch(uploadFileApi(payload, token)),
    dispatchResetFile: () => dispatch(resetFile()),
    // ---  brand vs branch ---
    // brand
    dispatchGetBrands: (payload, token) => dispatch(getBrandsApi(payload, token)),
    dispatchCreateBrand: (payload, token) => dispatch(createBrandApi(payload, token)),
    dispatchDeleteBrand: (payload, token) => dispatch(deleteBrandApi(payload, token)),
    dispatchUpdateBrand: (payload, token) => dispatch(updateBrandApi(payload, token)),
    dispatchResetBrandData: () => dispatch(resetBrandData()),
    // branch
    dispatchGetBranches: (payload, token) => dispatch(getBranchesApi(payload, token)),
    dispatchCreateBranch: (payload, token) => dispatch(createBranchApi(payload, token)),
    dispatchDeleteBranch: (payload, token) => dispatch(deleteBranchApi(payload, token)),
    dispatchUpdateBranch: (payload, token) => dispatch(updateBranchApi(payload, token)),
    // sign-out
    dispatchLogout: () => dispatch(logoutAction()),
  }),
};

export const createDealProps = {
  mapStateToProps: ({ deal, file }) => ({
    createdId: deal.createdId,
    fileUpload: file.fileUpload,
  }),
};

export const dealProps = {
  mapStateToProps: ({ auth, deal, brand }) => ({
    userDetail: auth.userDetail,
    isSuccess: deal.isSuccess,
    dealList: deal.dealList,
    brands: brand.brands,
    branches: brand.branches,
    deal: deal.dealDetail,
    deletedDeal: deal.deletedDeal,
  }),
  mapDispatchToProps: dispatch => ({
    dispatchResetDeal: () => dispatch(resetDealAction()),
    dispatchGetBrands: (payload, token) => dispatch(getBrandsApi(payload, token)),
    dispatchGetBranches: (payload, token) => dispatch(getBranchesApi(payload, token)),
  }),
};

export const brandProps = {
  mapStateToProps: ({ brand, common }) => ({
    createdBrand: brand.createdBrand,
    deletedBrand: brand.deletedBrand,
    createdBranch: brand.createdBranch,
    deletedBranch: brand.deletedBranch,
    isError: common.isError,
  }),
};

export default null;
