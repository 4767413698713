import { FILTER_KEY } from './filters';

const { CATEGORY, TYPE, BRAND, BRANCH, METHOD, PRIORITY, LOCATION, WARD } = FILTER_KEY;

export const TAG = {
  FILE: 'file',
  CHECKBOX: 'checkbox',
  DATE: 'datePicker',
  TEXT: 'text',
  RICH_TEXT: 'textArea',
  EMAIL: 'email',
  PWD: 'password',
  OTHER: 'other',
};

export default {
  IMAGE_SIZE: 5120000,
  FIELD_CHUNK: 8,
  TOKEN: 'userJwt',
  USERID: 'userId',
  EMAIL: {
    NAME: 'email',
    LABEL: 'Email',
  },
  PASSWORD: {
    NAME: 'password',
    LABEL: 'Mật khẩu',
  },
  get LOGIN() {
    return [
      { label: this.EMAIL.LABEL, type: TAG.EMAIL, name: this.EMAIL.NAME },
      { label: this.PASSWORD.LABEL, type: TAG.PWD, name: this.PASSWORD.NAME },
    ];
  },
  NAME: 'name',
  FIELD_NAMES: {
    EMAIL: 'email',
    PASSWORD: 'password',
    BRAND: 'brand',
    LOGO: 'logo',
    WEBSITE: 'website',
    BRANCH: 'branch',
    ADDRESS: 'address',
    TYPE: 'type',
    CATEGORY: 'category',
    LOCATION: 'location',
    METHOD: 'method',
    PRIORITY: 'priority',
    EXPIRE: 'expire',
    OPEN_TIME: 'open',
    DISTRICT: 'district',
    WARD: 'ward',
    DEAL: 'deal',
    DEAL_TITLE: 'title',
    SLUG: 'slug',
    DEAL_DETAIL: 'detail',
    PICTURE: 'picture',
    THUMBNAIL: 'thumbnail',
    QR: 'qrCode',
    BILL: 'bill',
    ACTIVE: 'active',
    USER: 'user',
    UPDATED: 'updatedAt',
  },
  FIELD_LABELS: {
    BRAND: 'Tên thương hiệu',
    LOGO: 'Logo thương hiệu',
    WEBSITE: 'Website',
    BRANCH: 'Tên chi nhánh',
    ADDRESS: 'Địa chỉ chi nhánh',
    B_PICTURE: 'Hình ảnh chi nhánh',
    TYPE: 'Loại ưu đãi',
    CATEGORY: 'Danh mục ưu đãi',
    LOCATION: 'Khu vực',
    METHOD: 'Phương thức áp dụng',
    PRIORITY: 'Độ ưu tiên',
    EXPIRE: 'Ngày hết hạn',
    DISTRICT: 'Quận / Huyện',
    WARD: 'Quận / Huyện',
    DEAL_TITLE: 'Tên ưu đãi',
    SLUG: 'Slug cho ưu đãi',
    OPEN_TIME: 'Giờ mở cửa',
    DEAL_DETAIL: 'Chi tiết ưu đãi',
    PICTURE: 'Hình ảnh Ưu đãi',
    THUMBNAIL: 'Liên kết đến ảnh ưu đãi',
    BILL: 'Áp dụng cho tất cả các hóa đơn',
    QR: 'Mã QR',
    ACTIVE: 'Trạng thái hoạt động',
    UPDATED: 'Cập nhật vào lúc',
  },

  get BRAND() {
    return [
      {
        label: this.FIELD_LABELS.LOGO,
        name: this.FIELD_NAMES.LOGO,
        tag: this.FIELD_NAMES.LOGO,
        type: TAG.FILE,
      },
      {
        label: this.FIELD_LABELS.BRAND,
        name: this.FIELD_NAMES.BRAND,
        length: 50,
      },
      {
        label: this.FIELD_LABELS.WEBSITE,
        name: this.FIELD_NAMES.WEBSITE,
        length: 300,
      },
    ];
  },
  get BRANCH() {
    return [
      {
        label: this.FIELD_LABELS.B_PICTURE,
        name: this.FIELD_NAMES.PICTURE,
        tag: this.FIELD_NAMES.PICTURE,
        type: TAG.FILE,
      },
      {
        label: this.FIELD_LABELS.BRANCH,
        name: this.FIELD_NAMES.BRANCH,
        length: 50,
      },
      {
        label: this.FIELD_LABELS.ADDRESS,
        name: this.FIELD_NAMES.ADDRESS,
        length: 500,
      },
      {
        label: this.FIELD_LABELS.BRAND,
        name: this.FIELD_NAMES.BRAND,
        tag: BRAND,
        type: TAG.OTHER,
      },
      {
        label: this.FIELD_LABELS.LOCATION,
        name: this.FIELD_NAMES.LOCATION,
        tag: LOCATION,
        type: TAG.OTHER,
      },
      {
        label: this.FIELD_LABELS.WARD,
        name: this.FIELD_NAMES.WARD,
        tag: WARD,
        type: TAG.OTHER,
      },
    ];
  },
  get INIT_SCHEMAS() {
    return {
      BRANCH: [
        [this.FIELD_NAMES.PICTURE, this.FIELD_NAMES.LOGO],
        [this.FIELD_NAMES.BRANCH, this.NAME],
        [this.FIELD_NAMES.ADDRESS, this.FIELD_NAMES.ADDRESS],
        [this.FIELD_NAMES.BRAND, this.FIELD_NAMES.BRAND],
        [this.FIELD_NAMES.LOCATION, this.FIELD_NAMES.LOCATION],
        [this.FIELD_NAMES.WARD, this.FIELD_NAMES.WARD],
      ],
    };
  },
  get CREATE_DEAL() {
    return [
      {
        name: this.FIELD_NAMES.DEAL_TITLE,
        label: this.FIELD_LABELS.DEAL_TITLE,
        length: 100,
        require: true,
      },
      {
        name: this.FIELD_NAMES.SLUG,
        label: this.FIELD_LABELS.SLUG,
        length: 100,
        require: true,
      },
      {
        label: this.FIELD_LABELS.CATEGORY,
        name: this.FIELD_NAMES.CATEGORY,
        tag: CATEGORY,
        type: TAG.OTHER,
      },
      {
        label: this.FIELD_LABELS.TYPE,
        name: this.FIELD_NAMES.TYPE,
        tag: TYPE,
        multiSelect: true,
        type: TAG.OTHER,
      },
      {
        label: this.FIELD_LABELS.METHOD,
        name: this.FIELD_NAMES.METHOD,
        tag: METHOD,
        multiSelect: true,
        type: TAG.OTHER,
      },
      {
        label: this.FIELD_LABELS.PRIORITY,
        name: this.FIELD_NAMES.PRIORITY,
        tag: PRIORITY,
        type: TAG.OTHER,
      },
      {
        label: this.FIELD_LABELS.BRAND,
        name: this.FIELD_NAMES.BRAND,
        tag: BRAND,
        type: TAG.OTHER,
      },
      {
        label: this.FIELD_LABELS.BRANCH,
        name: this.FIELD_NAMES.BRANCH,
        tag: BRANCH,
        multiSelect: true,
        type: TAG.OTHER,
      },
      {
        label: this.FIELD_LABELS.OPEN_TIME,
        name: this.FIELD_NAMES.OPEN_TIME,
        length: 100,
      },
      {
        label: this.FIELD_LABELS.DEAL_DETAIL,
        name: this.FIELD_NAMES.DEAL_DETAIL,
        length: 1000,
        type: TAG.RICH_TEXT,
      },
      {
        label: this.FIELD_LABELS.EXPIRE,
        name: this.FIELD_NAMES.EXPIRE,
        tag: this.FIELD_NAMES.EXPIRE,
        type: TAG.DATE,
      },
      {
        label: this.FIELD_LABELS.PICTURE,
        name: this.FIELD_NAMES.PICTURE,
        tag: this.FIELD_NAMES.PICTURE,
        type: TAG.FILE,
      },
      {
        label: this.FIELD_LABELS.QR,
        name: this.FIELD_NAMES.QR,
        tag: this.FIELD_NAMES.QR,
        type: TAG.FILE,
      },
      {
        label: this.FIELD_LABELS.THUMBNAIL,
        name: this.FIELD_NAMES.THUMBNAIL,
        type: TAG.OTHER,
      },
      {
        label: this.FIELD_LABELS.BILL,
        name: this.FIELD_NAMES.BILL,
        type: TAG.CHECKBOX,
        tag: this.FIELD_NAMES.BILL,
      },
      {
        label: this.FIELD_LABELS.ACTIVE,
        name: this.FIELD_NAMES.ACTIVE,
        type: TAG.CHECKBOX,
        tag: this.FIELD_NAMES.ACTIVE,
      },
    ];
  },
};
