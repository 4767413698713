import { createApiRequest } from './helpers';
import ACTIONS from '../constants/actions';
import createAction from './createAction';
import {
  createBrand,
  getBrands,
  createBranch,
  getBranches,
  updateBrand,
  updateBranch,
  deleteBrand,
  deleteBranch,
} from '../api/brand';

export const createBrandApi = createApiRequest(
  createBrand,
  true,
  ACTIONS.BRAND.CREATE_BRAND,
);

export const createBranchApi = createApiRequest(
  createBranch,
  true,
  ACTIONS.BRAND.CREATE_BRANCH,
);

export const getBrandsApi = createApiRequest(getBrands, true, ACTIONS.BRAND.GET_BRANDS);

export const getBranchesApi = createApiRequest(
  getBranches,
  true,
  ACTIONS.BRAND.GET_BRANCHES,
);

export const resetBrandData = createAction(ACTIONS.BRAND.RESET_DATA);

export const updateBrandApi = createApiRequest(
  updateBrand,
  true,
  ACTIONS.BRAND.UPDATE_BRAND,
);

export const updateBranchApi = createApiRequest(
  updateBranch,
  true,
  ACTIONS.BRAND.UPDATE_BRANCH,
);

export const deleteBrandApi = createApiRequest(
  deleteBrand,
  true,
  ACTIONS.BRAND.DELETE_BRAND,
);

export const deleteBranchApi = createApiRequest(
  deleteBranch,
  true,
  ACTIONS.BRAND.DELETE_BRANCH,
);
