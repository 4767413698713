/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import { string, objectOf, any, arrayOf, bool, func } from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typo from '@material-ui/core/Typography';
import IDown from '@material-ui/icons/KeyboardArrowDown';
import IClear from '@material-ui/icons/Clear';
import HomeCheckbox from './HomeCheckbox';
import HomeGrid from './HomeGrid';
import useClickOff from '../hooks/useClickOff';
import DatePicker from './datePicker/datePicker';
import HomeImage from './HomeImage';
import { TAG } from '../constants/form';
import COLORS from '../constants/colors';
import LAYOUT from '../constants/layout';
import { deAccent } from '../utils/string';

const useStyles = makeStyles(
  ({ spacing, typography, palette, zIndex, shape, transitions }) => ({
    root: {
      position: 'relative',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: spacing(4.5, 1, 3),
      minWidth: spacing(40),
      border: `0.5px solid ${COLORS.DARK_GREY}`,
      paddingRight: spacing(1),
      transition: transitions.easing.custom,
    },
    rootError: {
      border: `0.5px solid ${COLORS.ERROR}`,
      background: palette.primary.lighter,
    },
    rootCheckbox: {
      border: 'none',
      justifyContent: 'flex-start',
    },
    label: {
      position: 'absolute',
      top: spacing(-2.5),
      textTransform: 'uppercase',
      fontSize: typography.body3.fontSize,
      fontWeight: typography.fontWeightMedium,
      userSelect: 'none',
    },
    input: {
      width: '100%',
      padding: spacing(1),
      fontSize: typography.body2.fontSize,
      border: 'none',
      outline: 'none',
      background: 'none',
    },
    icon: {
      width: spacing(3),
      height: spacing(3),
      fill: palette.secondary.main,
    },
    iconActive: {
      fill: palette.primary.main,
    },
    iconInActive: {
      fill: palette.secondary.light,
    },
    helpers: {
      position: 'absolute',
      top: '100%',
      right: 0,
      height: 0,
      overflow: 'hidden',
      transition: transitions.easing.custom,
      fontSize: typography.body3.fontSize,
      lineHeight: 1.75,
    },
    errorText: {
      color: COLORS.ERROR,
      minHeight: spacing(3),
      height: spacing(3),
    },
    data: {
      position: 'absolute',
      top: 0,
      height: 0,
      transition: transitions.easing.custom,
      background: palette.background.paper,
      overflow: 'hidden',
    },
    showData: {
      width: '100%',
      height: spacing(21),
      border: `1px solid ${palette.secondary.light}`,
      zIndex: zIndex.tooltip,
      padding: spacing(1),
      overflow: 'auto',
      boxShadow: COLORS.SHADOW_STANDARD,
      borderRadius: shape.rectRadius,
    },
    searchBox: {
      position: 'relative',
      marginBottom: spacing(1),
    },
    search: {
      width: '100%',
      padding: spacing(1),
      outline: 'none',
      border: `1px solid ${palette.secondary.main}`,
      fontSize: typography.body2.fontSize,
      borderRadius: shape.rectRadius,
    },
    iClear: {
      position: 'absolute',
      width: spacing(3),
      height: spacing(3),
      top: '50%',
      transform: 'translateY(-50%)',
      right: spacing(1),
      fill: palette.primary.main,
    },
    itemWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
        background: palette.secondary.lighter,
      },
    },
    item: {
      padding: spacing(1),
      fontSize: typography.body2.fontSize,
      width: '90%',
    },
    iActive: {
      fontWeight: typography.fontWeightMedium,
      color: palette.primary.main,
    },
    checkbox: {
      visibility: 'hidden',
      width: 0,
    },
    tick: {
      position: 'relative',
      width: spacing(4),
      height: spacing(4),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    ticked: {
      position: 'absolute',
      width: spacing(4),
      height: spacing(4),
      top: 0,
    },
    richText: {
      padding: spacing(1),
      height: spacing(12.75),
      minHeight: spacing(4.25),
      minWidth: spacing(40),
      width: '100%',
      border: 'none',
      outline: 'none',
      resize: 'none',
    },
  }),
);

const HomeInput = ({
  label,
  type,
  errors,
  touched,
  list,
  multiSelect,
  onChange,
  textArea,
  className,
  values,
  onSelect,
  name,
  onBlur,
  reset,
  ...rest
}) => {
  const s = useStyles();
  const listRef = useRef();
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [enabledList, setEnabledList] = useState(false);
  const [listDisabled, setListDisabled] = useState(false);
  const [toggleList, setToggleList] = useState(false);
  const [valueDisplay, setValueDisplay] = useState('');
  const [textValue, setTextValue] = useState('');
  const [multiSelection, setMultiSelection] = useState({});
  const [openList, setOpenList] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const checkbox = type === TAG.CHECKBOX;
  const datePicker = type === TAG.DATE;
  const file = type === TAG.FILE;
  const richText = type === TAG.RICH_TEXT;
  const other = type === TAG.OTHER;
  const itext = type === TAG.TEXT || type === TAG.EMAIL || type === TAG.PWD;
  const { SHOW_ITEM_SEARCH } = LAYOUT;

  useEffect(() => {
    if (!touched[name] && values[name]) {
      setTextValue(values[name]);
    }
  }, [values, touched]);

  useEffect(() => {
    setErrorText(errors[name] || '');
    setError(touched[name] && Boolean(errors[name]));
  }, [errors, touched]);

  useEffect(() => {
    const isList = Array.isArray(list);
    if (isList) {
      setShowSearchBox(list.length > SHOW_ITEM_SEARCH);
    }
    setEnabledList(isList);
    setListDisabled(isList && list.length === 0);
    setSearchList(list);
  }, [list]);

  useEffect(() => {
    if (reset) {
      setTextValue('');
    }
  }, [reset]);

  useEffect(() => {
    if (Array.isArray(values[name])) {
      const displays = values[name].reduce(
        (acc, item) => [...acc, get(item, 'name')],
        [],
      );
      setValueDisplay(displays.join(', '));
    } else if (typeof values[name] === 'object') {
      setValueDisplay(get(values[name], 'name', ''));
    } else {
      setValueDisplay(values[name] || '');
    }
  }, [values]);

  useEffect(() => {
    setOpenList(other && !listDisabled && !toggleList);
  }, [toggleList, listDisabled]);

  const handleOpenList = () => {
    setToggleList(true);
    onBlur(name);
  };

  const handleClickOutside = () => {
    if (toggleList) {
      setToggleList(false);
    }
  };

  useClickOff(listRef, handleClickOutside);

  const handleResetList = () => {
    setSearchText('');
    setSearchList(list);
  };

  const handleSelectItem = item => () => {
    onSelect(item);
    setToggleList(false);
    handleResetList();
  };

  const handleSelectDate = (_, dateTimeString) => {
    onSelect(dateTimeString);
  };

  const handleCheckValue = evt => {
    const {
      target: { checked },
    } = evt;
    onSelect(checked);
  };

  const handleClickCheck = id => () => {
    document.getElementById(id).click();
  };

  const handleMultiChange = id => evt => {
    const {
      target: { checked },
    } = evt;
    const currentSelection = {
      ...multiSelection,
      [id]: checked,
    };
    setMultiSelection(currentSelection);
    const currentIds = Object.keys(currentSelection).filter(
      key => currentSelection[key],
    );
    const idPattern = currentIds.toString();
    const selectedItems = list.filter(item => idPattern.search(get(item, 'id')) > -1);

    onSelect(selectedItems.length > 0 ? selectedItems : undefined);
  };

  const handleSearchItem = evt => {
    if (evt) {
      evt.preventDefault();
    }
    const text = get(evt, 'target.value', '');
    setSearchText(text);
    const result = list.filter(
      item =>
        deAccent(get(item, 'name', '').toLowerCase()).search(
          deAccent(text.toLowerCase()),
        ) > -1,
    );
    setSearchList(result);
  };

  const handleCloseSearch = () => {
    handleResetList();
  };

  const handleChangeText = evt => {
    if (evt) {
      evt.preventDefault();
    }
    const {
      target: { value },
    } = evt;
    setTextValue(value);
    onChange(evt);
  };

  const rootAction = openList ? handleOpenList : noop;

  return (
    <HomeGrid
      className={classNames(
        s.root,
        className,
        error && s.rootError,
        checkbox && s.rootCheckbox,
      )}
      onClick={checkbox ? handleClickCheck(name) : rootAction}
    >
      <label htmlFor={name} className={s.label}>
        {label}
      </label>
      {richText && (
        <textarea
          className={s.richText}
          name={name}
          onBlur={onBlur}
          onFocus={onBlur}
          value={get(values, name, '')}
          onChange={onChange}
          {...rest}
        />
      )}
      {file && (
        <HomeImage
          initImage={get(values, name)}
          name={name}
          onChange={onSelect}
          onBlur={onBlur}
        />
      )}
      {datePicker && (
        <DatePicker
          name={name}
          time
          value={get(values, name)}
          selectDate={handleSelectDate}
        />
      )}
      {checkbox && (
        <HomeCheckbox
          id={name}
          name={name}
          value={get(values, name, false)}
          onCheck={handleCheckValue}
        />
      )}
      {itext && (
        <input
          className={s.input}
          name={name}
          type={type}
          onBlur={onBlur}
          onChange={handleChangeText}
          value={textValue}
          {...rest}
        />
      )}
      {other && (
        <input
          className={s.input}
          value={valueDisplay}
          onChange={onChange}
          {...rest}
          disabled={enabledList}
        />
      )}
      {enabledList && (
        <IDown
          className={classNames(
            s.icon,
            enabledList && s.iconActive,
            listDisabled && s.iconInActive,
          )}
        />
      )}
      <HomeGrid className={classNames(s.helpers, error && s.errorText)}>
        {errorText}
      </HomeGrid>
      <HomeGrid
        ref={listRef}
        className={classNames(s.data, toggleList && enabledList && s.showData)}
      >
        {showSearchBox && (
          <HomeGrid className={s.searchBox}>
            <input
              value={searchText}
              onChange={handleSearchItem}
              className={s.search}
              placeholder={label}
            />
            {searchText && <IClear className={s.iClear} onClick={handleCloseSearch} />}
          </HomeGrid>
        )}
        {enabledList &&
          !multiSelect &&
          searchList.map(item => {
            const id = get(item, 'id');
            const iName = get(item, 'name');
            const iActive = iName === valueDisplay;

            return (
              <HomeGrid
                key={id}
                className={s.itemWrap}
                onClick={handleSelectItem(item)}
              >
                <Typo className={classNames(s.item, iActive && s.iActive)}>
                  {iName}
                </Typo>
              </HomeGrid>
            );
          })}
        {enabledList &&
          multiSelect &&
          searchList.map(item => {
            const id = get(item, 'id');
            const iName = get(item, 'name');
            const ids = get(values, name, []).map(e => get(e, 'id'));
            const checked = Number(ids.indexOf(id)) > -1;
            return (
              <HomeGrid key={id} className={s.itemWrap} onClick={handleClickCheck(id)}>
                <Typo className={s.item}>{iName}</Typo>
                <HomeCheckbox
                  id={id}
                  name={iName}
                  value={checked}
                  onCheck={handleMultiChange(id)}
                />
              </HomeGrid>
            );
          })}
      </HomeGrid>
    </HomeGrid>
  );
};

HomeInput.propTypes = {
  label: string,
  type: string,
  name: string,
  onBlur: func,
  className: string,
  errors: objectOf(any),
  touched: objectOf(any),
  list: arrayOf(any),
  multiSelect: bool,
  textArea: bool,
  onSelect: func,
  onChange: func,
  values: objectOf(any),
  reset: bool,
};

HomeInput.defaultProps = {
  label: '',
  type: 'text',
  name: '',
  onBlur: noop,
  className: '',
  errors: {},
  touched: {},
  list: null,
  multiSelect: false,
  textArea: false,
  onSelect: noop,
  onChange: noop,
  values: {},
  reset: false,
};

export default HomeInput;
