import React from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HomeCredit from '../../svgs/homecredit.svg';
import MESSAGES from '../../constants/messages';
import ROUTER from '../../constants/router';
import COLORS from '../../constants/colors';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0, 0),
    background: theme.palette.primary.dark,
    boxShadow: COLORS.SHADOW_STANDARD,
    width: '100%',
  },
  title: {
    color: theme.palette.primary.contrastText,
  },
  logo: {
    cursor: 'pointer',
  },
}));

const Banner = ({ siteTitle }) => {
  const s = useStyles();
  const handleGoHomePage = () => {
    navigate(ROUTER.CUSTOMER.ROOT);
  };

  return (
    <Grid
      component="header"
      container
      direction="column"
      alignItems="center"
      className={s.root}
    >
      <Grid component="div" item onClick={handleGoHomePage} className={s.logo}>
        <HomeCredit />
      </Grid>
      <Grid component="div" item className={s.title}>
        <Typography variant="subtitle2" gutterBottom>
          Administration - {siteTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

Banner.propTypes = {
  siteTitle: string,
};

Banner.defaultProps = {
  siteTitle: MESSAGES.HC,
};

export default Banner;
