import moment from 'moment';
import chunk from 'lodash/chunk';
import { CALENDAR_WEEKS, DAYS_OF_WEEK } from './constants';

const THIS_MONTH = moment().month() + 1;
const THIS_YEAR = moment().year();

export const zeroPad = (value, length) => `${value}`.padStart(length, '0');
export const calendar = (month = THIS_MONTH, year = THIS_YEAR) => {
  // First day and number of days in month
  const daysOfMonth = moment(`${year}-${zeroPad(month, 2)}`, 'YYYY-MM').daysInMonth();
  // Fill out day to 6 weeks
  const daysOfPreviousMonth = moment(`${year}-${zeroPad(month, 2)}-01`).isoWeekday();
  const daysOfNextMonth = CALENDAR_WEEKS * 7 - (daysOfPreviousMonth + daysOfMonth);
  // Get the previous and next month and year
  const previousCalendar = moment(`${year}-${zeroPad(month, 2)}`).subtract(1, 'months');
  const previousMonth = previousCalendar.month() + 1;
  const previousYear = previousCalendar.year();
  const nextCalendar = moment(`${year}-${zeroPad(month, 2)}`).add(1, 'months');
  const nextMonth = nextCalendar.month() + 1;
  const nextYear = nextCalendar.year();
  // Get number of days in previous month
  const previousMonthDays = moment(
    `${previousYear}-${zeroPad(previousMonth, 2)}`,
    'YYYY-MM',
  ).daysInMonth();
  // Build dates to be displayed from previous month
  const previousMonthDates = [
    ...Array.from({ length: daysOfPreviousMonth }, (_, index) => {
      const day = index + 1 + (previousMonthDays - daysOfPreviousMonth);
      return [previousYear, zeroPad(previousMonth, 2), zeroPad(day, 2)];
    }),
  ];
  // Build dates to be displayed in current month
  const currentMonthDates = [
    ...Array.from({ length: daysOfMonth }, (_, index) => {
      const day = index + 1;
      return [year, zeroPad(month, 2), zeroPad(day, 2)];
    }),
  ];
  // Build dates to be displayed from next month
  const nextMonthDates = [
    ...Array.from({ length: daysOfNextMonth }, (_, index) => {
      const day = index + 1;
      return [nextYear, zeroPad(nextMonth, 2), zeroPad(day, 2)];
    }),
  ];
  // Combine all dates
  return chunk(
    [...previousMonthDates, ...currentMonthDates, ...nextMonthDates],
    DAYS_OF_WEEK,
  );
};
