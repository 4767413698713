import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import moment from 'moment';
import chunk from 'lodash/chunk';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import uuid from 'uuid/v1';
import { CLOCK, HOUR_TIME, MINUTE_TIME } from './constants';

import { zeroPad } from './helper';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
  },
  labelText: {
    textAlign: 'center',
    padding: theme.spacing(0.8, 0),
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    background: theme.palette.primary.light,
    color: theme.palette.text.secondary,
  },
  timeWrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  time: {
    padding: '8.08px 12.4px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
    borderRadius: theme.shape.round,
    transition: 'all 0.4s ease',
  },
  timeActive: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
  },
}));

const Clock = ({ time, onSelectHour, onSelectMinute }) => {
  const s = useStyles();
  const [state, setState] = useState({
    hours: Array.from({ length: 24 }, (_, index) => index),
    minutes: Array.from({ length: 6 }, (_, index) => index * 10),
    currentHour: moment().format('HH'),
    currentMinute: moment().format('mm'),
  });
  const [hour, setHour] = useState(time.replace(HOUR_TIME, '$1'));
  const [minute, setMinute] = useState(time.replace(MINUTE_TIME, '$1'));

  useEffect(() => {
    setState({
      ...state,
      time,
    });
    // onSelectHour(hour || state.currentHour);
    // onSelectMinute(minute || state.currentMinute);
  }, [time]);

  const handleSelectHour = selectedHour => () => {
    onSelectHour(selectedHour);
    setHour(selectedHour);
  };

  const handleSelectMinute = selectedMinute => () => {
    onSelectMinute(selectedMinute);
    setMinute(selectedMinute);
  };

  return (
    <Grid component="div" container direction="column" className={s.root}>
      <Typography className={s.labelText}>GIỜ</Typography>
      {chunk(state.hours, 6).map(row => (
        <Grid key={uuid()} component="div" item className={s.timeWrap}>
          {row.map(item => {
            const displayHour = zeroPad(item, 2);
            const hourStyle =
              `${displayHour}` === `${hour}` ? `${s.time} ${s.timeActive}` : s.time;
            return (
              <Typography
                key={`${item}`}
                color="inherit"
                className={hourStyle}
                onClick={handleSelectHour(displayHour)}
              >
                {displayHour}
              </Typography>
            );
          })}
        </Grid>
      ))}
      <Typography className={s.labelText}>PHÚT</Typography>
      <Grid component="div" item className={s.timeWrap}>
        {state.minutes.map(item => {
          const displayMinute = zeroPad(item, 2);
          const hourStyle =
            `${displayMinute}` === `${minute}` ? `${s.time} ${s.timeActive}` : s.time;
          return (
            <Typography
              key={`${item}`}
              color="inherit"
              className={hourStyle}
              onClick={handleSelectMinute(displayMinute)}
            >
              {displayMinute}
            </Typography>
          );
        })}
      </Grid>
    </Grid>
  );
};

Clock.propTypes = {
  time: string,
  onSelectHour: func.isRequired,
  onSelectMinute: func.isRequired,
};

Clock.defaultProps = {
  time: moment().format(CLOCK),
};

export default Clock;
