import React, { useState } from 'react';
import { func, objectOf, any } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Schedule from '@material-ui/icons/Schedule';
import chunk from 'lodash/chunk';
import noop from 'lodash/noop';
import randomKey from 'uuid/v1';
import Clock from './clock';
import {
  MONTH_ROW,
  YEAR_ROW,
  DATE_NAME,
  WEEKDAYS,
  MONTHS,
  MONTH_NAMES,
  CLOCK,
  HOUR_TIME,
  MINUTE_TIME,
} from './constants';
import HcVN from '../../images/hcvn.png';
import { calendar, zeroPad } from './helper';

const useStyles = makeStyles(
  ({ spacing, palette, typography, shape, shadows, zIndex, transitions }) => ({
    root: {
      border: `1px solid ${palette.primary.main}`,
      borderRadius: shape.borderRadius,
      overflow: 'hidden',
      background: palette.background.default,
      userSelect: 'none',
      boxShadow: shadows[10],
    },
    item: {
      width: '100%',
    },
    header: {
      width: 'auto',
      padding: spacing(1, 0),
      background: palette.primary.dark,
      color: palette.primary.contrastText,
      position: 'relative',
      zIndex: 10000,
    },
    headerTop: {
      borderBottom: `1px solid ${palette.primary.main}`,
      marginBottom: spacing(1),
      display: 'flex',
      padding: spacing(0, 1),
      zIndex: zIndex.tooltip,
    },
    hcvn: {
      position: 'absolute',
      height: '100%',
      transform: 'rotateY(180deg)',
      zIndex: zIndex.appBar,
    },
    dateYearNav: {
      zIndex: zIndex.tooltip,
    },
    dateControl: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: spacing(0, 1),
    },
    weekDateLabel: {
      padding: spacing(0.25, 0),
      background: palette.primary.main,
      color: palette.primary.contrastText,
    },
    weekDateItem: {
      width: spacing(4.5),
      display: 'flex',
      justifyContent: 'center',
      alignItem: 'center',
    },
    week: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    date: {
      color: palette.text.primary,
      padding: spacing(0.75, 0.5),
      width: spacing(4.5),
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      opacity: 0.5,
      transition: transitions.easing.sharp,
      '&:hover': {
        color: palette.text.primary,
        textShadow: `${spacing(0.125, 0.125)} ${palette.text.disabled}`,
      },
    },
    currentMonth: {
      background: palette.primary.light,
      opacity: 1,
      '&:hover': {
        color: palette.text.secondary,
      },
    },
    today: {
      background: palette.primary.main,
      color: palette.primary.contrastText,
    },
    month: {
      width: spacing(8),
      display: 'flex',
      justifyContent: 'center',
      padding: '9.1px 41px',
      textAlign: 'center',
      cursor: 'pointer',
      border: `1px solid ${palette.background.paper}`,
      '&:hover': {
        background: palette.primary.light,
        opacity: 0.8,
        color: palette.text.primary,
      },
    },
    yearLabel: {
      fontSize: typography.subtitle2.fontSize,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    year: {
      width: spacing(10.5),
      cursor: 'pointer',
      padding: '19.87px 18px',
      border: `1px solid ${palette.background.paper}`,
      '&:hover': {
        background: palette.primary.light,
        opacity: 0.8,
        color: palette.text.primary,
      },
    },
    invalidDate: {
      background: palette.background.default,
      color: palette.text.disabled,
      cursor: 'unset',
      '&:hover': {
        background: palette.background.default,
        color: palette.text.disabled,
      },
    },
    icon: {
      width: spacing(3),
      height: spacing(3),
      fill: palette.background.paper,
      transition: 'all 0.5s ease-in',
    },
    iconClock: {
      width: spacing(3.5),
      height: spacing(3.5),
      margin: spacing(0, 0.5),
      transition: 'all 0.5s ease-in',
    },
    iconClockUp: {
      fill: palette.primary.main,
      transform: 'rotate(360deg)',
    },
    iconUp: {
      transform: 'rotate(-180deg)',
      fill: palette.primary.main,
    },
    timeWrap: {
      width: '100%',
    },
    clock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      cursor: 'pointer',
    },
    time: {
      cursor: 'pointer',
    },
    cta: {
      margin: spacing(0),
      padding: spacing(0.625, 0),
    },
  }),
);

const Calendar = ({
  onDateChanged,
  onTimeChanged,
  onClose,
  minDate,
  maxDate,
  date,
}) => {
  const s = useStyles();
  const [selectedDate, selectDate] = useState({
    selectedDate: date,
    date: date.format('DD'),
    month: date.month() + 1,
    year: date.year(),
    valid: true,
  });
  const [selectedMonth, selectMonth] = useState(date.month() + 1);
  const [selectedYear, selectYear] = useState(date.year());
  const [toggleMonthSelection, toggleMonth] = useState(false);
  const [toggleYearSelection, toggleYear] = useState(false);
  const [clock, setClock] = useState(moment().format(CLOCK));
  const [toggleClockSelection, toggleClock] = useState(false);
  const getCalendarDates = () => calendar(selectedMonth, selectedYear);

  const handleToggleMonth = () => {
    toggleMonth(!toggleMonthSelection);
    toggleYear(false);
    toggleClock(false);
  };

  const handleToggleYear = () => {
    toggleYear(!toggleYearSelection);
    toggleMonth(false);
    toggleClock(false);
  };

  const handleToggleClock = () => {
    toggleYear(false);
    toggleMonth(false);
    toggleClock(!toggleClockSelection);
  };

  const handleSelectClockHour = hour => {
    const currentTime = clock.replace(MINUTE_TIME, `${hour}:$1`);
    setClock(currentTime);
    onTimeChanged(`${currentTime}:00`);
  };

  const handleSelectClockMinute = minute => {
    const currentTime = clock.replace(HOUR_TIME, `$1:${minute}`);
    setClock(currentTime);
    onTimeChanged(`${currentTime}:00`);
    handleToggleClock();
  };

  // Render month and year header
  const createCalendarHeader = time => {
    const nameOfMonth = MONTH_NAMES[selectedMonth];
    const arrowMonthStyle = toggleMonthSelection ? `${s.icon} ${s.iconUp}` : s.icon;
    const arrowYearStyle = toggleYearSelection ? `${s.icon} ${s.iconUp}` : s.icon;
    const clockStyle = toggleClockSelection
      ? `${s.iconClock} ${s.iconClockUp}`
      : s.iconClock;

    return (
      <Grid
        component="div"
        container
        direction="column"
        justify="space-evenly"
        className={s.header}
      >
        <img src={HcVN} alt="hcvn" className={s.hcvn} />
        <Grid component="div" item className={s.headerTop}>
          <Grid
            component="div"
            container
            direction="column"
            justify="space-evenly"
            alignItems="flex-end"
          >
            <Grid component="div" item>
              <Grid component="div" container>
                <Typography variant="subtitle2" color="inherit">
                  {DATE_NAME[date.day()]},
                </Typography>
                <Typography variant="subtitle2" color="inherit">
                  <span>&nbsp;</span>
                  {date.format('DD')}
                  <span>&nbsp;</span>
                </Typography>
                <Typography variant="subtitle2" color="inherit">
                  {nameOfMonth}
                </Typography>
              </Grid>
            </Grid>
            <Grid component="div" item>
              {time && (
                <Grid
                  component="div"
                  item
                  className={s.clock}
                  onClick={handleToggleClock}
                >
                  <Schedule className={clockStyle} />
                  <Typography variant="subtitle1" color="inherit" className={s.time}>
                    {clock}
                  </Typography>
                </Grid>
              )}
              {!time && (
                <Typography variant="subtitle1" color="inherit">
                  {date.format('YYYY')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid component="div" item className={s.dateYearNav}>
          <Grid component="div" container justify="space-between">
            <Grid component="div" item>
              <Typography
                variant="subtitle2"
                color="inherit"
                onClick={handleToggleMonth}
                className={s.dateControl}
              >
                {nameOfMonth}
                <span>&nbsp;</span>
                <KeyboardArrowDown className={arrowMonthStyle} />
              </Typography>
            </Grid>
            <Grid component="div" item>
              <Typography
                variant="subtitle2"
                color="inherit"
                onClick={handleToggleYear}
                className={s.dateControl}
              >
                {selectedYear}
                <span>&nbsp;</span>
                <KeyboardArrowDown className={arrowYearStyle} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // Render the label for day of week
  // eslint-disable-next-line
  const createWeekDateLabel = ({ name }) => (
    <Grid component="div" item key={name} className={s.weekDateItem}>
      <Typography color="inherit">{name}</Typography>
    </Grid>
  );

  // Render calendar date as returned from the calendar builder function
  const gotoDate = goDate => () => {
    onDateChanged(goDate);
    selectDate({
      selectedDate: goDate,
      date: goDate.format('DD'),
      month: goDate.month() + 1,
      year: goDate.year(),
      valid: true,
    });
    selectMonth(goDate.month() + 1);
    selectYear(goDate.year());
  };

  const onMonthSelected = value => () => {
    gotoDate(
      moment(`${[selectedYear, zeroPad(value, 2), selectedDate.date].join('-')}`),
    );
    selectDate({
      ...selectedDate,
      valid: false,
    });
    selectMonth(value);
    toggleMonth(!toggleMonthSelection);
  };

  const onYearSelected = year => () => {
    gotoDate(
      moment(`${[year, zeroPad(selectedMonth, 2), selectedDate.date].join('-')}`),
    );
    selectDate({
      ...selectedDate,
      valid: false,
    });
    selectYear(year);
    toggleYear(!toggleYearSelection);
  };

  const createCalendarDate = (curDate, index) => {
    const $date = moment(`${curDate.join('-')}`);
    const isToday = $date.isSame(selectedDate.selectedDate, 'day');
    const inMonth =
      selectedMonth && selectedYear && $date.isSame(selectedDate.selectedDate, 'month');
    const isValidDate = $date <= maxDate && $date >= minDate;
    const onClick = isValidDate ? gotoDate($date) : noop;
    const props = { index, onClick };
    let dateStyle = s.date;
    dateStyle = inMonth ? `${dateStyle} ${s.currentMonth}` : dateStyle;
    dateStyle = isToday ? `${dateStyle} ${s.today}` : dateStyle;
    dateStyle = isValidDate ? dateStyle : `${dateStyle} ${s.invalidDate}`;

    return (
      <Grid component="div" container justify="space-evenly" key={randomKey()}>
        <Grid component="div" item key={randomKey()} className={dateStyle} {...props}>
          <Typography variant="caption" color="inherit">
            {moment($date).format('DD')}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  // Render calendar dates
  const createDates = () =>
    getCalendarDates().map(week => (
      <Grid component="div" item key={randomKey()} className={s.week}>
        {week.map((day, index) => createCalendarDate(day, index))}
      </Grid>
    ));

  const createMonthsCalendar = () => {
    const quarter = chunk(MONTHS, MONTH_ROW);
    return quarter.map(monthRow => (
      <Grid component="div" item key={randomKey()}>
        <Grid component="div" container justify="space-between">
          {monthRow.map(({ value, name }) => {
            const currentMonthDate = moment(
              `${[selectedYear, zeroPad(value, 2), zeroPad(maxDate.date(), 2)].join(
                '-',
              )}`,
            );

            const isValidMonth =
              currentMonthDate <= maxDate && currentMonthDate >= minDate;
            let monthStyle = s.month;
            monthStyle =
              selectedDate.month === value ? `${monthStyle} ${s.today}` : monthStyle;
            monthStyle = isValidMonth ? monthStyle : `${monthStyle} ${s.invalidDate}`;
            const onClick = isValidMonth ? onMonthSelected(value) : noop;
            const props = {
              className: monthStyle,
              onClick,
            };
            return (
              <Grid component="div" item key={name} {...props}>
                <Typography color="inherit" className={s.monthLabel}>
                  {name}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    ));
  };

  const createMonthsMatrix = () => (
    <Fade in timeout={500}>
      <Grid component="div" container direction="column">
        {createMonthsCalendar()}
      </Grid>
    </Fade>
  );

  const parseYearRange = () => {
    const yearRange = Array.from(
      { length: maxDate.year() - minDate.year() + 1 },
      (value, index) => minDate.year() + index,
    );
    return chunk(yearRange, YEAR_ROW);
  };

  const createYearCalendar = () => {
    const yearMatrix = parseYearRange();
    return yearMatrix.map(yearRow => {
      let fullYearRow = [];
      if (yearRow.length < YEAR_ROW) {
        fullYearRow = Array.from(
          { length: YEAR_ROW },
          (value, tInd) => tInd + yearRow[0],
        );
      } else {
        fullYearRow = yearRow.slice();
      }
      return (
        <Grid component="div" item key={randomKey()}>
          <Grid component="div" container justify="space-evenly">
            {fullYearRow.map(year => {
              const currentYear = moment(
                `${[year, zeroPad(selectedMonth, 2), zeroPad(maxDate.date(), 2)].join(
                  '-',
                )}`,
              );
              const isValidYear = currentYear <= maxDate && currentYear >= minDate;
              let yearStyle = s.year;

              yearStyle =
                selectedDate.year === year ? `${yearStyle} ${s.today}` : yearStyle;
              yearStyle = isValidYear ? yearStyle : `${yearStyle} ${s.invalidDate}`;
              const onClick = isValidYear ? onYearSelected(year) : noop;
              const props = {
                className: yearStyle,
                onClick,
              };
              return (
                <div key={year} {...props}>
                  <Typography color="inherit" className={s.yearLabel}>
                    {year}
                  </Typography>
                </div>
              );
            })}
          </Grid>
        </Grid>
      );
    });
  };

  const createYearMatrix = () => (
    <Fade in timeout={500}>
      <Grid component="div" container direction="column" justify="space-evenly">
        {createYearCalendar()}
      </Grid>
    </Fade>
  );

  const handleClose = () => {
    onClose();
  };

  const createOKButton = () => (
    <Button
      href=""
      color="primary"
      onClick={handleClose}
      disabled={!selectedDate.valid}
      fullWidth
      className={s.cta}
    >
      CHỌN
    </Button>
  );

  const showTime = onTimeChanged !== null;

  const showDate =
    !toggleMonthSelection && !toggleYearSelection && !toggleClockSelection;
  return (
    <Grow in>
      <Grid component="div" container direction="column" className={s.root}>
        <Grid component="div" item className={s.item}>
          {createCalendarHeader(showTime)}
        </Grid>
        <Grid component="div" item className={s.item}>
          {showDate && (
            <Grid
              component="div"
              container
              justify="space-evenly"
              className={s.weekDateLabel}
            >
              {WEEKDAYS.map(createWeekDateLabel)}
            </Grid>
          )}
          {showDate && (
            <Fade in timeout={500}>
              <Grid component="div" container direction="column" justify="space-evenly">
                {createDates()}
              </Grid>
            </Fade>
          )}
          {toggleMonthSelection && createMonthsMatrix()}
          {toggleYearSelection && createYearMatrix()}
          {toggleClockSelection && (
            <Clock
              time={moment().format(CLOCK)}
              onSelectHour={handleSelectClockHour}
              onSelectMinute={handleSelectClockMinute}
            />
          )}
        </Grid>
        <Grid component="div" item className={s.item}>
          {showDate && createOKButton()}
        </Grid>
      </Grid>
    </Grow>
  );
};

Calendar.propTypes = {
  date: objectOf(any),
  maxDate: objectOf(any).isRequired,
  minDate: objectOf(any).isRequired,
  onDateChanged: func,
  onTimeChanged: func,
  onClose: func,
};

Calendar.defaultProps = {
  date: moment(),
  onDateChanged: noop,
  onTimeChanged: null,
  onClose: noop,
};

export default Calendar;
