import * as Yup from 'yup';
import get from 'lodash/get';
import FORM from '../constants/form';
import REGEXP from '../constants/regExp';
import MESSAGE from '../constants/messages';

const { FIELD_NAMES, FIELD_LABELS, IMAGE_SIZE, LOGIN } = FORM;
const { CREATE } = MESSAGE;

export const login = Yup.object().shape({
  [LOGIN[0].name]: Yup.string()
    .matches(REGEXP.EMAIL, { message: CREATE.ERROR.FORMAT })
    .required(`${LOGIN[0].label} ${CREATE.ERROR.REQUIRED}`),
  [LOGIN[1].name]: Yup.string().required(`${LOGIN[1].label} ${CREATE.ERROR.REQUIRED}`),
});

export const deal = Yup.object().shape({
  [FIELD_NAMES.DEAL_TITLE]: Yup.string()
    .matches(REGEXP.DEAL_TITLE, { message: CREATE.ERROR.SHORT })
    .required(`${FIELD_LABELS.DEAL_TITLE} ${CREATE.ERROR.REQUIRED}`),
  [FIELD_NAMES.SLUG]: Yup.string()
    .matches(REGEXP.SLUG, { message: CREATE.ERROR.FORMAT })
    .required(`${FIELD_LABELS.SLUG} ${CREATE.ERROR.REQUIRED}`),
  [FIELD_NAMES.CATEGORY]: Yup.object().required(
    `${FIELD_LABELS.CATEGORY} ${CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.TYPE]: Yup.array().required(
    `${FIELD_LABELS.TYPE} ${CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.METHOD]: Yup.array().required(
    `${FIELD_LABELS.METHOD} ${CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.PRIORITY]: Yup.object().required(
    `${FIELD_LABELS.PRIORITY} ${CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.BRAND]: Yup.object().required(
    `${FIELD_LABELS.BRAND} ${CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.BRANCH]: Yup.array().required(
    `${FIELD_LABELS.BRANCH} ${CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.DEAL_DETAIL]: Yup.string().required(
    `${FIELD_LABELS.DEAL_DETAIL} ${CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.PICTURE]: Yup.mixed()
    .test('size', CREATE.ERROR.SIZE, file => {
      const size = get(file, 'size');
      if (!size) {
        return true;
      }
      return size <= IMAGE_SIZE;
    })
    .required(`${FIELD_LABELS.PICTURE} ${CREATE.ERROR.REQUIRED}`),
  [FIELD_NAMES.EXPIRE]: Yup.string().required(
    `${FIELD_LABELS.EXPIRE} ${CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.THUMBNAIL]: Yup.string().matches(REGEXP.URL, {
    message: CREATE.ERROR.FORMAT,
  }),
});

export const brand = Yup.object().shape({
  [FIELD_NAMES.LOGO]: Yup.mixed()
    .test('size', CREATE.ERROR.SIZE, file => {
      const size = get(file, 'size');
      if (!size) {
        return true;
      }
      return size <= IMAGE_SIZE;
    })
    .required(`${FIELD_LABELS.LOGO} ${CREATE.ERROR.REQUIRED}`),
  [FIELD_NAMES.BRAND]: Yup.string()
    .matches(REGEXP.BRAND_NAME, { message: MESSAGE.CREATE.ERROR.SHORT })
    .required(`${FIELD_LABELS.BRAND} ${CREATE.ERROR.REQUIRED}`),
  [FIELD_NAMES.WEBSITE]: Yup.string().matches(REGEXP.URL, {
    message: CREATE.ERROR.FORMAT,
  }),
});

export const branches = Yup.object().shape({
  [FIELD_NAMES.PICTURE]: Yup.mixed().test('size', CREATE.ERROR.SIZE, file => {
    const size = get(file, 'size');
    if (!size) {
      return true;
    }
    return size <= IMAGE_SIZE;
  }),
  [FIELD_NAMES.BRANCH]: Yup.string()
    .matches(REGEXP.BRANCH_NAME, { message: `${MESSAGE.CREATE.ERROR.SHORT}` })
    .required(`${FIELD_LABELS.BRANCH} ${MESSAGE.CREATE.ERROR.REQUIRED}`),
  [FIELD_NAMES.ADDRESS]: Yup.string().required(
    `${FIELD_LABELS.ADDRESS} ${MESSAGE.CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.BRAND]: Yup.string().required(
    `${FIELD_LABELS.BRAND} ${MESSAGE.CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.LOCATION]: Yup.string().required(
    `${FIELD_LABELS.LOCATION} ${MESSAGE.CREATE.ERROR.REQUIRED}`,
  ),
  [FIELD_NAMES.WARD]: Yup.string(),
});
